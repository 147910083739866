exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-catalogue-category-strapi-categories-strapi-id-tsx": () => import("./../../../src/pages/catalogue/category/{StrapiCategories.strapiId}.tsx" /* webpackChunkName: "component---src-pages-catalogue-category-strapi-categories-strapi-id-tsx" */),
  "component---src-pages-catalogue-index-tsx": () => import("./../../../src/pages/catalogue/index.tsx" /* webpackChunkName: "component---src-pages-catalogue-index-tsx" */),
  "component---src-pages-catalogue-product-strapi-products-strapi-id-tsx": () => import("./../../../src/pages/catalogue/product/{StrapiProducts.strapiId}.tsx" /* webpackChunkName: "component---src-pages-catalogue-product-strapi-products-strapi-id-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-guides-birthstones-tsx": () => import("./../../../src/pages/guides/birthstones.tsx" /* webpackChunkName: "component---src-pages-guides-birthstones-tsx" */),
  "component---src-pages-guides-index-tsx": () => import("./../../../src/pages/guides/index.tsx" /* webpackChunkName: "component---src-pages-guides-index-tsx" */),
  "component---src-pages-guides-ring-sizing-tsx": () => import("./../../../src/pages/guides/ring-sizing.tsx" /* webpackChunkName: "component---src-pages-guides-ring-sizing-tsx" */),
  "component---src-pages-guides-traditional-anniversary-gifts-tsx": () => import("./../../../src/pages/guides/traditional-anniversary-gifts.tsx" /* webpackChunkName: "component---src-pages-guides-traditional-anniversary-gifts-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-repairs-tsx": () => import("./../../../src/pages/repairs.tsx" /* webpackChunkName: "component---src-pages-repairs-tsx" */)
}

